import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import ParishPhoto from "../images/parish_photo.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {
  PageHeaderText,
  InfoContainerEven,
  InfoContainerOdd,
  InfoInside,
  InfoHeader,
  Info,
  ButtonContainerEven,
  LinkButton,
  Blue,
  Red,
} from "../components/common"

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${ParishPhoto});
  background-size: cover;
  display: flex;
`

const AboutUs = () => (
  <Layout container>
    <SEO title="About Us" />
    <TitleImage>
      <PageHeaderText>About Us</PageHeaderText>
    </TitleImage>
    <InfoContainerEven>
      <InfoInside>
        <InfoHeader>
          On a Mission <Blue>from God</Blue>
        </InfoHeader>
        <Info>
          We are a lively, enthusiastic congregation joyfully living our Mission
          From God through community, outreach, formation, and worship. Together
          we are proud to honor where we come from, together we are living as
          God's beloved day by day, and together we are growing into the future
          God dreams for us.
        </Info>
      </InfoInside>
    </InfoContainerEven>
    <ButtonContainerEven>
      <LinkButton>
        <Link to="/Staff">
          Our Staff <Red> > </Red>
        </Link>
      </LinkButton>
    </ButtonContainerEven>
    <InfoContainerEven>
      <InfoInside>
        <InfoHeader>
          Accepting of <Blue>Everyone</Blue>
        </InfoHeader>
        <Info>
          No matter who you are; gender, race, social or economic background,
          sexual orientation or marital status; God has a deep and abiding love
          for you. So, whoever you are or wherever you may be on you spiritual
          journey; we invite you to find a place of welcome, fellowship and
          spiritual growth here at St. Alban’s.
        </Info>
      </InfoInside>
    </InfoContainerEven>
    <InfoContainerOdd>
      <InfoInside>
        <InfoHeader>
          Historical, <Blue>Yet Active</Blue>
        </InfoHeader>
        <Info>
          St. Alban’s is a parish of the Episcopal Diocese of Milwaukee, located
          in the Village of Sussex, Wisconsin. St. Alban’s was founded in 1842,
          but don’t let our age fool you. We are an active, vibrant and
          welcoming Christian community of men, women and children, who seek to
          follow the teachings and example of Jesus Christ through worship of
          God, service to neighbor and formation for spiritual growth. As
          Episcopalians, we are members of the world-wide Anglican Communion,
          the third largest Christian denomination in the world, which is part
          of Christ’s One, Holy, Catholic and Apostolic Church. As Anglicans, we
          are Catholic and Protestant; reformed and reforming; faithful and
          seeking; contemplative and active.
        </Info>
      </InfoInside>
    </InfoContainerOdd>
    {/* <ButtonContainer2>
      <LinkButton>
        History and Cemetary <Red> > </Red>
      </LinkButton>
    </ButtonContainer2> */}
  </Layout>
)

export default AboutUs
